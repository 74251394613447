import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"

const Contact = () => {
  const heroAnim = useRef()

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 1500)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (window.lottie) {
      window.lottie.loadAnimation({
        container: heroAnim.current,
        path: "/lottie/hero.json",
        renderer: "svg",
      })
    }
  }, [])

  useEffect(() => {
    Array.from(document.querySelectorAll(".wrap-text")).forEach(el => {
      el.innerHTML = el.textContent.replace(/\S/g, "<span>$&</span>")
    })
  }, [])

  return (
    <Layout>
      <Seo title="Contact" />
      <Helmet>
        <body className="contact-page" />
      </Helmet>

      <section className="hero-main">
        <div className="hero-anim" ref={heroAnim} />
        <div className="container">
          <div className="offset-2">
            <div className={`content ${loading ? "" : "visible"}`}>
              <h1 style={{ maxWidth: 800 }}>
                <span className="wrap-text">
                  <span>Tell</span>
                </span>
                <span>&nbsp;</span>
                <span className="wrap-text">
                  <span>us</span>
                </span>
                <span>&nbsp;</span>
                <span className="wrap-text">
                  <span>more</span>
                </span>
                <span>&nbsp;</span>
                <span className="wrap-text">
                  <span>about</span>
                </span>
                <span>&nbsp;</span>
                <span className="wrap-text">
                  <span> you.</span>
                </span>
              </h1>
              <p style={{ maxWidth: 750 }}>
                We’re excited to get to know you! Fill in the details below, and
                we’ll reach out to you soon
              </p>
              <p style={{ marginTop: 45 }}>
                Meanwhile,{" "}
                <span style={{ color: "#fff" }}> Stay home stay safe.</span>
              </p>
            </div>
          </div>
        </div>

        <div className="hero-bottom-content">
          <div className="container">
            <div className="flex-row">
              <div className="col col-6">
                <div className="scroll-down">
                  <p>Scroll</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="visible-mobile">
        <img src="/img/contact-photo-mob.png" alt="contact" />
      </div>
      <section
        className="contact-form-area"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="container">
          <div className="flex-row">
            <div className="col col-5 hidden-mobile">
              <div className="contact-img" data-watch-visibility>
                <img src="/img/contact-img.jpg" alt="contact" />
              </div>
            </div>

            <div className="col col-7">
              <div className="form-block">
                <h2 data-watch-visibility>Let&apos;s talk.</h2>

                <div className="contact-form" data-watch-visibility>
                  <form
                    name="contact"
                    method="POST"
                    data-netlify="true"
                    netlify-honeypot="bot-field"
                  >
                    <input
                      type="hidden"
                      hidden
                      name="form-name"
                      value="contact"
                    />
                    <p class="hidden">
                      <label>
                        Don’t fill this out if you’re human:{" "}
                        <input name="bot-field" />
                      </label>
                    </p>

                    <div className="form-group">
                      <label htmlFor="name">name</label>
                      <input
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Hey, Tell us your name"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="What’s your mail id?"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">phone</label>
                      <input
                        id="phone"
                        type="phone"
                        name="phone"
                        placeholder="Your number?"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        id="message"
                        name="message"
                        placeholder="What do you want to talk about?"
                        required
                      />
                    </div>
                    <h5 className="privacy-text">
                      By submitting this form I consent to processing my
                      personal data as described in the{" "}
                      <Link to="/">Privacy Policy. </Link>.
                    </h5>
                    <div className="form-group">
                      <button type="submit" className="button-arrow">
                        Shooot{" "}
                        <svg
                          width="19"
                          height="16"
                          viewBox="0 0 19 16"
                          fill="none"
                        >
                          <path
                            fill="#EA502A"
                            d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="our-culture" data-watch-visibility>
            <p style={{ color: "#EA502A" }}>AND ALSO</p>
            <h2>Jump into our culture</h2>
            <Link to="/about/" className="button-arrow">
              <svg width="19" height="16" viewBox="0 0 19 16" fill="none">
                <path
                  fill="#fff"
                  d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </section>

      <div className="footer-cta">
        <div className="container">
          <div className="flex-row align-items-center" data-watch-visibility>
            <div className="col col-7">
              <h2>
                We have some cool stuff in our
                <span className="orange" style={{ display: "inline" }}>
                  {" "}
                  projects gallery.
                </span>
              </h2>
            </div>
            <div className="col col-5">
              <Link
                to="/#portfolio"
                className="flex-row footer-cta-block no-margin align-items-center"
              >
                <div>
                  <p>Yeah</p>
                  <h4>Check it out now</h4>
                </div>
                <span className="button-arrow">
                  <svg width="19" height="16" viewBox="0 0 19 16" fill="none">
                    <path
                      fill="#fff"
                      d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
                    ></path>
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
